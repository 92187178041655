
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  NbAutocompleteModule,
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbTimepickerModule,
  NbToastrModule,
  NbWindowModule,
  NbTreeGridModule,
  NbTreeGridCellDirective,
  NbLayoutModule,
  NbThemeModule
} from '@nebular/theme';
import { ExcelService } from './services/excel.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CsvService } from './services/csv.service';
import { ApiInterceptor } from './services/interceptor/apiinterceptor.interceptor';
import { LoaderComponent } from './common/loader/loader.component';
import { SharingService } from './pages/service/sharing.service';
import { JwtInterceptor } from './services/interceptor/jwt.interceptor';
import { UpdateDateHttpInterceptor } from './services/interceptor/updatedatehttp.interceptor';

@NgModule({
  declarations: [AppComponent,LoaderComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    NbAutocompleteModule,
    NbThemeModule.forRoot(),
    NbLayoutModule,
    NbTreeGridModule,
  ],
  providers: [CsvService, ExcelService, SharingService, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
   NbTreeGridCellDirective
],
  bootstrap: [AppComponent],
})
export class AppModule {
}
